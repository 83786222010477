<template>
  <div>
    <div id="grapesjs-editor" style="height: 100vh; border: 1px solid #ddd;"></div>
    <Loader ref="loader" />
  </div>
</template>

<script>
// Importar el editor desde el archivo donde se inicializa
import grapesjs from 'grapesjs';
import 'grapesjs/dist/css/grapes.min.css';
import grapesjsPresetWebpage from 'grapesjs-preset-webpage';
import grapesjsBlocksBasic from 'grapesjs-blocks-basic';
import grapesjsPluginForms from 'grapesjs-plugin-forms';
import grapesjsNavbar from 'grapesjs-navbar';
import esTranslations from '@/assets/locale/es';
import {encryptAES, getUser, getUserSub, horaAhora} from "@/config/servicios/campana/util";
import {sending_addFileZip} from "@/config/servicios/campana/envios/servicesProgramados";
import Loader from "@/components/Modal/Loader.vue";
let editor = null;

export default {
  name: 'GrapesEditor',
  components: {Loader},
  props: {
    responseTags: {
      type: Array,
      required: true,
    },
    editorVisible: {
      type: Boolean,
      default: false,
    },
    project: {
      type: String,
      default: '',
    },
    cargarPlantilla: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update-body-html'],
  watch: {
    cargarPlantilla() {
      if (this.cargarPlantilla) {
        this.loadTemplateMethod(editor);
      }
    },
  },
  mounted() {
    this.initializeEditor();
  },
  beforeUnmount() {
    // Asegurarse de destruir el editor para evitar errores
    if (editor) {
      editor.destroy();
      editor = null;
    }
  },
  methods: {
    llevarInline(htmlString) {
      // Crear un DOM virtual para manipular el HTML
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlString, 'text/html');

      // Obtener las hojas de estilo del bloque <style>
      const styles = doc.querySelectorAll('style');
      const styleRules = {};

      styles.forEach((style) => {
        const rules = style.sheet ? style.sheet.cssRules : [];
        for (const rule of rules) {
          if (rule.selectorText && rule.style) {
            styleRules[rule.selectorText] = rule.style.cssText;
          }
        }
        // Eliminar el bloque <style> del HTML
        style.remove();
      });

      // Aplicar estilos en línea según las reglas
      Object.keys(styleRules).forEach((selector) => {
        const elements = doc.querySelectorAll(selector);
        elements.forEach((el) => {
          const existingStyle = el.getAttribute('style') || '';
          el.setAttribute('style', `${existingStyle} ${styleRules[selector]}`);
        });
      });

      // Retornar el HTML con los estilos in-line aplicados
      return doc.body.innerHTML;
    },
    saveTemplateMethod() {
      try {
        const project = JSON.stringify(editor.getProjectData());
        const htmlWithCss = '<style>' + editor.getCss() + '</style>' + editor.getHtml();
        const dataEdit = {
          project: project,
          vista: this.llevarInline(htmlWithCss)
        };

        // Usa this.$emit para emitir el evento en Vue
        this.$emit('update-body-html', dataEdit);
      } catch (error) {
        console.error('Error al guardar la plantilla:', error);
        alert('Error al guardar la plantilla.');
      }
    },
    loadTemplateMethod(editor) {
      try {
        if (this.project) {
          // Utilizar requestAnimationFrame para prevenir el congelamiento
          requestAnimationFrame(() => {
            editor.loadProjectData(JSON.parse(this.project.project));
          });
        } else {
          alert('No hay ninguna plantilla guardada.');
        }
      } catch (error) {
        console.error('Error al cargar la plantilla:', error);
        alert('Error al cargar la plantilla.');
      }
    },
    initializeEditor() {
      const self = this; // Guardar referencia a 'this' del componente Vue
      // Usa nextTick para asegurar que el DOM está completamente renderizado
      this.$nextTick(() => {
        editor = grapesjs.init({
          container: '#grapesjs-editor',
          height: '100vh',
          width: 'auto',
          fromElement: true,
          plugins: [
            grapesjsPresetWebpage,
            grapesjsBlocksBasic,
            grapesjsPluginForms,
            grapesjsNavbar,
          ],
          pluginsOpts: {
            'grapesjs-preset-webpage': {},
            'grapesjs-blocks-basic': {},
            'grapesjs-plugin-forms': {},
            'grapesjs-navbar': {},
          },
          i18n: {
            locale: 'es',
            messages: {
              es: esTranslations,
            },
          },
        });

            // Agregar un botón personalizado a un panel
            editor.Panels.addButton('options', [{
              id: 'import-zip',
              className: 'fa fa-upload', // Usar icono de FontAwesome (opcional)
              command: 'import-zip-command', // Vincula con el comando que crearemos
              attributes: { title: 'Importar archivo ZIP o RAR' }
            }]);

          // Crear el comando que manejará la importación
            editor.Commands.add('import-zip-command', {
              run(editor, sender) {
                sender && sender.set('active', false); // Detener el comando si ya está activo

                // Crear input para subir el archivo
                const inputFile = document.createElement('input');
                inputFile.type = 'file';
                inputFile.accept = '.zip'; // Aceptar solo archivos .zip y .rar
                inputFile.style.display = 'none'; // Escondemos el input para que no interfiera con la interfaz
                document.body.appendChild(inputFile);

                // Evento que se dispara cuando el usuario selecciona el archivo
                inputFile.addEventListener('change', async (event) => {
                  const file = event.target.files[0];
                  if (file) {
                    // Aquí puedes manejar la subida y procesamiento del archivo
                    // Lógica para procesar archivo ZIP o RAR (usar JSZip o unrar.js)
                    console.log('Archivo seleccionado:', file.name);
                    // Llamar a la función enviarZip con el archivo seleccionado
                    try {
                      await self.enviarZip(file, editor);
                    } catch (error) {
                      console.error("Error al enviar el archivo ZIP:", error);
                    }
                  }
                  // Limpia el input después de procesar
                  document.body.removeChild(inputFile);
                });

                // Disparar la ventana de subida
                inputFile.click();
              }});

        // Configurar el título del botón `gjs-open-import-webpage`
        const importButton = editor.Panels.getButton('options', 'gjs-open-import-webpage');
        if (importButton) {
          importButton.set('attributes', { title: 'Importar Página Web' });
        }
        //const saveTemplate = this.saveTemplateMethod;
        this.setupEditor();
      });
    },
    setupEditor() {
      const rte = editor.RichTextEditor;
      rte.add('selectTag', {
        icon: `<span style="font-size: 16px; cursor: pointer;margin-right: 1rem;" title="Insertar Variable">📝</span>`,
        event: 'click',
        result: (rte, action) => {
          const selectHTML = this.generateTagSelectHTML();
          const selectElement = document.createElement('div');
          selectElement.innerHTML = selectHTML;

          selectElement.firstChild.addEventListener('change', (event) => {
            const selectedTag = event.target.value;
            if (selectedTag) {
              rte.insertHTML(selectedTag);
              event.target.value = "elegir-tag"; // Resetear el select después de la inserción
            }
          });

          action.btn.appendChild(selectElement.firstChild);
          var divsToRemove = document.getElementsByClassName("gjs-tags");
          for (var i = divsToRemove.length-1; i >= 1; i--) {
            divsToRemove[i].remove();
          }
        },
      });

      // Añadir los botones y comandos personalizados aquí
      editor.Panels.removeButton('options', [
        'export-template',
        //'gjs-open-import-webpage',
        'open-layers',
        'open-blocks',
      ]);

      /*editor.Panels.addButton('options', {
        id: 'save-template',
        className: 'fa fa-floppy-o',
        command: 'save-template-command',
        attributes: {title: 'Salvar Plantilla'},
        active: false,
      });

      editor.Panels.addButton('options', {
        id: 'load-template',
        className: 'fa fa-folder-open-o',
        command: 'load-template-command',
        attributes: {title: 'Cargar Plantilla'},
        active: false,
      });*/

      // Registrar comandos personalizados
     /* editor.Commands.add('save-template-command', {
        run() {
          saveTemplate(editor);
        },
      });*/
    },
    clearEditor() {
      if (editor) {
        // Limpiar el contenido del editor de forma segura
        try {
          editor.DomComponents.clear();
          // Añadir un contenido inicial básico
          //this.editor.addComponents('<div>Nuevo contenido inicial</div>'); // Opcional
        } catch (error) {
          console.error('Error al limpiar el editor:', error);
        }
      }
    },
    generateTagSelectHTML() {
      if (this.responseTags && this.responseTags.entries.length) {
        let selectHTML = '<select class="gjs-tags"><option value="elegir-tag" disables selected>Elegir Variables</option>';
        this.responseTags.entries.forEach(tag => {
          selectHTML += `<option value="${tag.value}">${tag.label}</option>`;
        });
        selectHTML += '</select>';
        return selectHTML;
      }
      return '<select class="gjs-tags"><option value="">No Hay Variables</option></select>';
    },
    async enviarZip(file, editor) {
      try {
        this.$refs.loader.showLoader();
        let user = getUserSub() != null ? getUserSub() : getUser();
        let js = {
          time_expired: horaAhora(),
          customer: JSON.stringify(user.customer)
        };

        // Crear un FormData para enviar el archivo y otros datos
        const formData = new FormData();
        formData.append("TOKEN", encryptAES(js)); // El token de seguridad encriptado
        formData.append("file", file); // El archivo ZIP como tal

        const response = await sending_addFileZip(formData);

        if (response.data.response === "200") {
          editor.setComponents(response.data.data);
          this.$refs.loader.hideLoader();
        } else {
          console.log("No se pudo cargar el Zip");
          this.$refs.loader.hideLoader();
        }
      } catch (error) {
        this.$refs.loader.hideLoader();
        console.error("Error al enviar zip", error);
      }
    }
  },
};
</script>

<style>
#grapesjs-editor .gjs-one-bg {
  background-color: #0033a0 !important; /* Aumenta la especificidad */
}
#grapesjs-editor {
  height: 100vh;
  border: 1px solid #ddd;
}
#grapesjs-editor .gjs-editor .gjs-rte-action {
  justify-content: flex-start;
}
.gjs-rte-action .gjs-tags {
  border-radius: 50px;
  font-size: 0.875rem;
  padding: .125rem .75rem;
  text-transform: capitalize;
  line-height: 1.4rem;
  color: #495057;
  background-color: #fff;
  border: 1px solid #d2d6da;
  margin-right: .5rem;
}
</style>
