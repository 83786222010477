import { createStore } from "vuex";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import {
  customer_field_available,
  generic_field,
  customer_field,
  tags,
  segment,
  contacts_delete,
  contact_detail_contact_group,
  add_segment,
  add_tag,
  get_filtros, edit_contacts_planner
} from "@/config/servicios/campana/contactos/servicesContNew";
import {encryptAES, getUser, getUserSub, horaAhora} from "@/config/servicios/campana/util";
import Swal from "sweetalert2";
import {obtenerLista} from "@/config/servicios/campana/envios/servicesEnvios";
import {LIST_TEMPLATE_WHATSPP_BY_CUSTOMER} from "@/config/servicios/campana/whatsapp/servicesWhatsapp";
import {add_short_url, available_short_url} from "@/config/servicios/campana/url/servicesUrl";
import {obtenerSenderEmail} from "@/config/servicios/campana/sender/servicesSenderEmail";
import {contact_groups, obtenerSender, obtenerSenderCustomer} from "@/config/servicios/campana/sender/servicesSender";
import moment from "moment";
import {obtenerDomainEmail} from "@/config/servicios/campana/Domain/domainServices";
import {status_channel} from "@/config/servicios/campana/canales/servicesCanales";
export default createStore({
  state: {
    timeActual: moment().format('HH:mm:ss A'),
    clienteActual: {},
    programado: null,
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    isRTL: false,
    color: "",
    sidebarType: "bg-white",
    darkMode: false,
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    layout: "default",
    bootstrap,

    botones: [],
    labelRR: 0,
    labelURL: 0,
    perfil: null,
    tomarVariablesMuestras: null,

    elementosPorPaginas: 10,
    datosPaginados: [],
    paginaActual: 1,

    responseGeneric_field: {},
    responseDetallesLista: {},
    responseCustomer_field_available: {},
    responseCustomer: [],
    cabeceras: [],
    responseTags: [],
    responseSegmentos: [],
    responsePlantillasSms: [],
    responsePlantillasEmail: [],

    datoSegmento: "",
    datoTag: [],
    datosPorFilas: [],
    valorMsisdn: "",
    valorEmail: "",
    contactPlannerList: [],
    contactPlanner: {
        contactPlannerId: null,
        firstName: null,
        lastName: null,
        address: null,
        msisdn: null,
        email: null,
        birthDate: null,
        company: null,
        contactGroupId: null,
        gender: null,
        tax: null,
        tags: null,
        segmentId: null,
        active: true,
        createdAt: null,
        updatedAt: null,
        country: null,
        customerId: null,
        customContactPlannerList:[
        ]
    },
    dataContactPlannerEditar: {
      contactPlannerId: null,
      contactName: null,
      contact_last_name: null,
      address: null,
      msisdn: null,
      email: null,
      birthDate: null,
      company: null,
      gender: null,
      tax: null,
      tags: null,
      segmentId: null,
      active: true,
      createdAt: null,
      updatedAt: null,
      country: null,
      customerId: null,
      contactGroupId: null,
      customContactPlannerList:[
      ]
    },
    customContactPlanner:     {
        customContactPlannerId: null,
        contactHeader: null,
        customerFieldId: {
          customerFieldId: null,
          customerFieldName: null
        }
      },

    individual: false,
    individualNumero: "",
    contactGroupId: "",
    vista2: false,
    botonesCab: [],
    addCab: [],
    cabecerasIndGenericas: [],
    cabecerasIndNoGen: [],
    cabecerasIndAgregar: [],

    get_filtros: [],

    editarContacto: false,
    dataEditarContacto: {},

    seleccion: '',
    LIST_TEMPLATE_WHATSPP_BY_CUSTOMER: {},
    lista_short_url: {},
    list_available_short_url: {},

    responseSenderSms: {},
    responserContactGroups: {},
    responseSenderSmsCustomer: {},

    eliminarActive: false,

    reporteDetallado: {},

    estado_canales: [],
    },
  mutations: {
    reporteDetallado(state, reporteDetallado) {
      state.reporteDetallado = reporteDetallado;
    },
    getResponsePlantillasSms(state, plantilla) {
      state.responsePlantillasSms = plantilla;
    },
    getResponsePlantillasEmail(state, plantilla) {
      state.responsePlantillasEmail = plantilla;
    },
    setTimeActual(state, time) {
      state.timeActual = time;
    },
    setClienteActual(state, cliente) {
      state.clienteActual = cliente;
    },
    setSeleccion(state, valor) {
      state.seleccion = valor;
    },
    setProgramado(state, programado) {
      state.programado = programado;
    },
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector("#app");

      if (sidenav_show.classList.contains("g-sidenav-show") && sidenav_show.classList.contains("g-sidenav-hidden")) {
        sidenav_show.classList.add("g-sidenav-pinned");
        sidenav_show.classList.remove("g-sidenav-hidden");
        state.isPinned = true;
      } else if (sidenav_show.classList.contains("g-sidenav-show") && sidenav_show.classList.contains("g-sidenav-pinned")) {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      } else if (sidenav_show.classList.contains("g-sidenav-show") && window.innerWidth < 1200 ) {
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-hidden");
        state.isPinned = true;
      }
    },
    setSidebarType(state, payload) {
      state.sidebarType = payload;
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
    toggleDefaultLayout(state) {
      state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },

    // Nueva mutación para agregar un nuevo botón al arreglo
    agregarBoton(state, nuevoBoton) {
      state.botones.push(nuevoBoton);
    },
    agregarBotonCab(state, nuevoBoton) {
      state.botonesCab.push(nuevoBoton);
    },
    agregarAddCab(state, nuevoBoton) {
      state.addCab.push(nuevoBoton);
    },
    eliminarBoton(state, id) {
      state.botones = state.botones.filter(b => b.id !== id.id);
    },
    eliminarBotonCab(state, id) {
      state.botonesCab = state.botonesCab.filter(b => b.id !== id.id);
    },
    eliminarAddCab(state, id) {
      state.addCab = state.addCab.filter(b => b.id !== id.id);
    },
    actualizarTextoBoton(state, { id, nuevoTexto, nuevoValorNumerico= "" }) {
      const boton = state.botones.find(boton => boton.id === id);
      if (boton) {
        boton.texto = nuevoTexto;
        boton.valorNumerico = nuevoValorNumerico;
      }
    },
    actualizarTextoBotonCab(state, { id, nuevoTexto }) {
      const boton = state.botonesCab.find(boton => boton.id === id);
      if (boton) {
        boton.valor = nuevoTexto;
      }
    },
    actualizarTextoAddCab(state, { id, cabecera , valor }) {
      const boton = state.addCab.find(boton => boton.id === id);
      if (boton) {
        boton.cabecera = cabecera;
        boton.valor = valor;
      }
    },
    cambiarLabelRR(state, nuevoValor) {
      state.labelRR = nuevoValor;
    },
    cambiarLabelURL(state, nuevoValor) {
      state.labelURL = nuevoValor;
    },
    cambiarPerfil(state, nuevoValor) {
      state.perfil = nuevoValor;
    },
    tomarVariablesMuestras(state, nuevoValor) {
      state.tomarVariablesMuestras = nuevoValor;
    },
    RESET_BOTONES(state) {
      state.botones = [];
    },
    RESET_Data_Paginada(state) {
      state.datosPaginados = [];
    },
    setCabeceras(state, nuevoValor) {
      state.cabeceras = nuevoValor;
    },
    setDatosPorFilas(state, nuevoValor) {
      state.datosPorFilas = nuevoValor;
    },
    cambiarDatoSegmento(state, nuevoValor) {
      state.datoSegmento = nuevoValor;
    },
    cambiarDatoTag(state, nuevoValor) {
      nuevoValor.forEach(element => {
        state.datoTag.push(element);
      });
    },
    cambiarIndividual(state, estado) {
      state.individual = estado;
    },
    cambiarEditarContacto(state, estado) {
      state.editarContacto = estado;
    },
    guardarEditarContacto(state, data) {
      state.dataEditarContacto = data;
    },
    cambiarIndividualNumero(state, nuevoValor) {
      if (nuevoValor === "569undefined" || nuevoValor === "569null" || nuevoValor === "569") {
        state.individualNumero = "";
      }else{
        state.individualNumero = nuevoValor;
      }
    },
    cambiarContactGroupId(state, nuevoValor) {
      state.contactGroupId = nuevoValor;
    },
    cambiarVista2(state, nuevoValor) {
      state.vista2 = nuevoValor;
    },
    cambiarEliminarActive(state, nuevoValor) {
      state.eliminarActive = nuevoValor;
    },
    agregarcabecerasIndGenericas(state, valor) {
      state.cabecerasIndGenericas.push(valor);
    },
    actualizarcabecerasIndGenericas(state, { id, valor }) {
      const cab = state.cabecerasIndGenericas.find(cab => cab.id === id);
      if (cab) {
        cab.valor = valor;
      }
    },
  },
  actions: {
    guardarProgramado({ commit }, programado) {
      commit('setProgramado', programado);
    },
    guardarSeleccion({ commit }, seleccion) {
      commit('setSeleccion', seleccion);
    },
    toggleSidebarColor({ commit }, payload) {
      commit("setSidebarType", payload);
    },

    // Acción para agregar un nuevo botón al arreglo
    agregarNuevoBoton({ commit }, nuevoBoton) {
      commit('agregarBoton', nuevoBoton);
    },
    agregarNuevoBotonCab({ commit }, nuevoBoton) {
      commit('agregarBotonCab', nuevoBoton);
    },
    agregarNuevoAddCab({ commit }, nuevoBoton) {
      commit('agregarAddCab', nuevoBoton);
    },
    // Acción para eliminar un botón del arreglo
    eliminarBoton({ commit }, id) {
      commit('eliminarBoton', id);
    },
    eliminarBotonCab({ commit }, id) {
      commit('eliminarBotonCab', id);
    },
    eliminarAddCab({ commit }, id) {
      commit('eliminarAddCab', id);
    },
    actualizarTextoBoton({ commit }, { id, nuevoTexto, nuevoValorNumerico }) {
      commit('actualizarTextoBoton', { id, nuevoTexto, nuevoValorNumerico });
    },
    actualizarTextoBotonCab({ commit }, { id, nuevoTexto }) {
      commit('actualizarTextoBotonCab', { id, nuevoTexto });
    },
    actualizarTextoAddCab({ commit }, { id, cabecera, valor }) {
      commit('actualizarTextoAddCab', { id, cabecera, valor });
    },
    encontrarBotonPorId({ state }, id) {
      return state.botones.find(boton => boton.id === id);
    },
    cambiarLabelRR({ commit }, nuevoValor) {
      commit('cambiarLabelRR', nuevoValor);
    },
    cambiarLabelURL({ commit }, nuevoValor) {
      commit('cambiarLabelURL', nuevoValor);
    },
    cambiarPerfil({ commit }, nuevoValor) {
      commit('cambiarPerfil', nuevoValor);
    },
    tomarVariablesMuestras({ commit }, nuevoValor) {
      commit('tomarVariablesMuestras', nuevoValor);
    },
    async addCabeceras({ commit }, nuevoValor) {
      commit('setCabeceras', nuevoValor);
    },
    addatosPorFilas({ commit }, nuevoValor) {
      commit('setDatosPorFilas', nuevoValor);
    },
    cambiarDatoSegmento({ commit }, nuevoValor) {
      commit('cambiarDatoSegmento', nuevoValor);
    },
    cambiarDatoTag({ commit }, nuevoValor) {
      commit('cambiarDatoTag', nuevoValor);
    },
    cambiarIndividual({ commit }, nuevoValor) {
      commit('cambiarIndividual', nuevoValor);
    },
    cambiarContactGroupId({ commit }, nuevoValor) {
      commit('cambiarContactGroupId', nuevoValor);
    },
    cambiarIndividualNumero({ commit }, nuevoValor) {
      commit('cambiarIndividualNumero', nuevoValor);
    },
    cambiarEditarContacto({ commit }, nuevoValor) {
      commit('cambiarEditarContacto', nuevoValor);
    },
    guardarEditarContacto({ commit }, nuevoValor) {
      commit('guardarEditarContacto', nuevoValor);
    },
    cambiarVista2({ commit }, nuevoValor) {
      commit('cambiarVista2', nuevoValor);
    },
    cambiarEliminarActive({ commit }, nuevoValor) {
      commit('cambiarEliminarActive', nuevoValor);
    },
    agregarcabecerasIndGenericas({ commit }, nuevoBoton) {
      commit('agregarcabecerasIndGenericas', nuevoBoton);
    },
    actualizarcabecerasIndGenericas({ commit }, { id, valor }) {
      commit('actualizarcabecerasIndGenericas', { id, valor });
    },
  },
  getters: {
    // Vuex getter
    customer_field: state => async (nuevoValor) => {
      try {
        let user = (getUserSub() != null) ? getUserSub() : getUser();

        let js = {
          "time_expired": horaAhora(),
          "customer": encodeURIComponent(JSON.stringify(user.customer)),
          "customerField": encodeURIComponent(JSON.stringify(nuevoValor))
        }

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));

        const response = await customer_field(body);

        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {
          state.responseCustomer_field_available = response.data.data;
        } else {
          console.log("No se encontró lista");
        }

        // Devuelve la respuesta
        return response;
      } catch (error) {
        console.error("Error al obtener datos", error);
        throw error; // Lanza el error para que pueda ser manejado externamente si es necesario
      }
    },
    generic_field: state =>  async () => {
      try {
        let user = (getUserSub()!=null) ? getUserSub() :getUser();

        let js = {
          "time_expired":horaAhora(),
          "customer_user":encodeURIComponent(JSON.stringify(user)),
          "customer":encodeURIComponent(JSON.stringify(user.customer))
        }

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await generic_field(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {

          state.responseGeneric_field = response.data.data;
        } else {
          console.log("No se encontró lista");

        }
      } catch (error) {
        console.error("Error al obtener datos", error);
      }
    },
    detalles_lista: state =>  async (lista) => {
      try {
        let user = (getUserSub()!=null) ? getUserSub() :getUser();

        let js = {
          "time_expired":horaAhora(),
          "customer":encodeURIComponent(JSON.stringify(user.customer)),
          "contact_group":encodeURIComponent(JSON.stringify(lista)),
        }

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await contact_detail_contact_group(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {

          state.responseDetallesLista = response.data.data;
        } else {
          Swal.fire({
            title: 'No se pudo obtener los detalles',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-celcom-orange',
            },
            type: 'error',
            icon: 'error'
          })

        }
      } catch (error) {
        Swal.fire({
          title: 'No se pudo obtener los detalles',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-celcom-orange',
          },
          type: 'error',
          icon: 'error'
        })
      }
    },
    delete_contanct: state =>  async (lista) => {
      try {
        let user = (getUserSub()!=null) ? getUserSub() :getUser();

        let js = {
          "time_expired":horaAhora(),
          "customer":encodeURIComponent(JSON.stringify(user.customer)),
          "contact_group":encodeURIComponent(JSON.stringify(lista)),
        }

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await contacts_delete(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {

          state.responseGeneric_field = response.data.data;
        } else {
          Swal.fire({
            title: 'No se pudo borrar lista',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-celcom-orange',
            },
            type: 'error',
            icon: 'error'
          })

        }
      } catch (error) {
        Swal.fire({
          title: 'No se pudo borrar lista',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-celcom-orange',
          },
          type: 'error',
          icon: 'error'
        })
      }
    },
    edit_contanct: state =>  async (lista) => {
      try {
        let user = (getUserSub()!=null) ? getUserSub() :getUser();

        let js = {
          "time_expired":horaAhora(),
          "customer":encodeURIComponent(JSON.stringify(user.customer)),
          "contact_group":encodeURIComponent(JSON.stringify(lista)),
        }

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await edit_contacts_planner(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {
          state.responseGeneric_field = response.data.data;
          Swal.fire({
            title: 'Lista Editada Correctamente',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-celcom-blue',
            },
            type: 'success',
            icon: 'success'
          })
        } else {
          Swal.fire({
            title: 'No se pudo editar lista',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-celcom-orange',
            },
            type: 'error',
            icon: 'error'
          })

        }
      } catch (error) {
        Swal.fire({
          title: 'No se pudo editar lista',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-celcom-orange',
          },
          type: 'error',
          icon: 'error'
        })
      }
    },
    customer_field_available: state =>  async () => {
      try {
        let user = (getUserSub()!=null) ? getUserSub() :getUser();

        let js = {
          "time_expired":horaAhora(),
          "customer_user":encodeURIComponent(JSON.stringify(user)),
          "customer":encodeURIComponent(JSON.stringify(user.customer))
        }

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await customer_field_available(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {

          state.responseCustomer_field_available = response.data.data;
        } else {
          console.log("No se encontró lista");

        }
      } catch (error) {
        console.error("Error al obtener datos", error);
      }
    },
    tagsGet: state =>  async () => {
      try {
        let user = (getUserSub()!=null) ? getUserSub() :getUser();

        let js = {
          "time_expired":horaAhora(),
          "customer_user":encodeURIComponent(JSON.stringify(user)),
          "customer":encodeURIComponent(JSON.stringify(user.customer))
        }

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await tags(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {
          state.responseTags = response.data.data;
        } else {
          console.log("No se encontró lista");

        }
      } catch (error) {
        console.error("Error al obtener datos", error);
      }
    },
    segmentosGet: state =>  async () => {
      try {
        let user = (getUserSub()!=null) ? getUserSub() :getUser();

        let js = {
          "time_expired":horaAhora(),
          "customer_user":encodeURIComponent(JSON.stringify(user)),
          "customer":encodeURIComponent(JSON.stringify(user.customer))
        }

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await segment(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {

          state.responseSegmentos = response.data.data;
        } else {
          console.log("No se encontró lista");

        }
      } catch (error) {
        console.error("Error al obtener datos", error);
      }
    },
    plantillasSmsGet: state =>  async () => {
      state.responsePlantillasSms = [];
        try {
          let user = (getUserSub()!=null) ? getUserSub() :getUser();
          let js = {
            time_expired: horaAhora(),
            customer: encodeURIComponent(JSON.stringify(user.customer))
          };

          var body = new URLSearchParams();
          body.append("TOKEN", encryptAES(js));


          const response = await obtenerLista(body);
          // Almacena la respuesta en la variable de datos
          if (response.data.response === "200") {
            state.responsePlantillasSms = response.data.data.filter(plantilla => plantilla.channelId === 1);
          } else {
            console.log("No se encontró lista");

          }
        } catch (error) {
          console.error("Error al obtener datos", error);
        }
    },
    plantillasEmailGet: state =>  async () => {
      state.responsePlantillasEmail = [];
      try {
        let user = (getUserSub()!=null) ? getUserSub() :getUser();
        let js = {
          time_expired: horaAhora(),
          customer: encodeURIComponent(JSON.stringify(user.customer))
        };

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await obtenerLista(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {
          state.responsePlantillasEmail = response.data.data.filter(plantilla => plantilla.channelId === 3);
        } else {
          console.log("No se encontró lista");

        }
      } catch (error) {
        console.error("Error al obtener datos", error);
      }
    },
    segmentAdd: () =>  async (segmentTag) => {
      try {
        let user = (getUserSub()!=null) ? getUserSub() :getUser();

        let js = {
          "time_expired":horaAhora(),
          "customer":encodeURIComponent(JSON.stringify(user.customer)),
          "segmentTag":encodeURIComponent(JSON.stringify(segmentTag)),
        }

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await add_segment(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {
          return  response.data;
        } else {
          console.log("No se encontró lista");
          return null;
        }
      } catch (error) {
        console.error("Error al obtener datos", error);
        return null;
      }
    },
    tagAdd: () =>  async (segmentTag) => {
      try {
        let user = (getUserSub()!=null) ? getUserSub() :getUser();

        let js = {
          "time_expired":horaAhora(),
          "customer":encodeURIComponent(JSON.stringify(user.customer)),
          "segmentTag":encodeURIComponent(JSON.stringify(segmentTag)),
        }

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await add_tag(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {
          return  response.data;
        } else {
          console.log("No se encontró lista");
          return null;
        }
      } catch (error) {
        console.error("Error al obtener datos", error);
        return null;
      }
    },
    arrayCabeceras: (state, getters) => async () => {
      await getters.generic_field();
      await getters.customer_field_available();
      await getters.tagsGet();
      await getters.segmentosGet();
      for (const tag in state.responseGeneric_field) {
        state.responseCustomer.push({
          id: state.responseGeneric_field[tag].basicHeaderFieldId,
          name: state.responseGeneric_field[tag].headerName,
          alias: state.responseGeneric_field[tag].alias,
        });
      }
      for (const tag in state.responseCustomer_field_available) {
        state.responseCustomer.push({
          id: state.responseCustomer_field_available[tag].customerFieldId,
          name: state.responseCustomer_field_available[tag].customerFieldName,
          alias: ""
        });
      }
    },
    totalPaginas: state => nuevoValor => {
      return Math.ceil(nuevoValor.length / state.elementosPorPaginas);
    },
    getPaginasVecinas: (state, getters) => (nuevoValor) => {
      const paginasVecinas = [];
      const paginasMostradas = 1;
      for (let i = state.paginaActual - paginasMostradas; i <= state.paginaActual + paginasMostradas; i++) {
        if (i > 0 && i <= getters.totalPaginas(nuevoValor)) {
          paginasVecinas.push(i);
        }
      }
      return paginasVecinas;
    },
    getDataPaginated: state => (noPagina, arrayDatos) => {
      state.paginaActual = noPagina;
      state.datosPaginados = [];
      const inicio = (noPagina * state.elementosPorPaginas) - state.elementosPorPaginas;
      const fin = (noPagina * state.elementosPorPaginas);
      state.datosPaginados = arrayDatos.slice(inicio, fin);
    },
    getPreviousPage: (state, getters) => (arrayDatos) => {
        if (state.paginaActual > 1) {
          state.paginaActual--;
          getters.getDataPaginated(state.paginaActual, arrayDatos);
        }
    },
    getNextPage: (state, getters) => (arrayDatos) => {
      if (state.paginaActual < getters.totalPaginas(arrayDatos)) {
        state.paginaActual++;
        getters.getDataPaginated(state.paginaActual, arrayDatos);
      }
    },
    isActive: state => noPagina => {
      return noPagina === state.paginaActual;
    },
    msisdnGet: state =>  async () => {

      state.datosPorFilas.forEach((fila) => {
        if (fila.segundaColumna === 'msisdn') {
          state.valorMsisdn = fila.primeraColumna;
        }else if (fila.segundaColumna === 'email') {
          state.valorEmail = fila.primeraColumna;
        }
      });
    },
    getValor: state =>  async (nuevoValor) => {
      let aux = null;
      state.datosPorFilas.forEach((fila) => {
        if (fila.segundaColumna === nuevoValor) {
          aux = fila.primeraColumna;
        }
      });
      return aux;
    },
    getValorInd: state =>  async (nuevoValor) => {
      let aux = null;
      state.cabecerasIndGenericas.forEach((fila) => {
        if (fila.name === nuevoValor) {
          aux = fila.valor;
        }
      });
      return aux;
    },
    contactPlanner: (state, getters) => async (archivoExcel, user) => {
      try {
        const tiempoActual = new Date().getTime();
        const elementosCoincidentes = await getters.customContactPlanner();
        const totalFilas = archivoExcel.length;
        const tamanoLote = 1000; // Tamaño del lote para procesar
        let filaInicio = 1;

        // Procesar en lotes
        const procesarLote = async () => {
          for (let i = filaInicio; i < Math.min(filaInicio + tamanoLote, totalFilas); i++) {
            let filaObjeto = {};

            // Combina el procesamiento de datos de Excel y la creación de contactPlanner en una sola iteración
            archivoExcel[0].forEach((key, index) => {
              filaObjeto[key] = archivoExcel[i] ? archivoExcel[i][index] : null; // Verificación para evitar errores si alguna fila está incompleta
            });

            const aux = { ...state.contactPlanner };
            const contactName = await getters.getValor('first_name');
            const contact_last_name = await getters.getValor('last_name');
            const address = await getters.getValor('address');
            const msisdn = await getters.getValor('msisdn');
            const email = await getters.getValor('email');
            const birthDate = await getters.getValor('birthDate');
            const company = await getters.getValor('company');
            const gender = await getters.getValor('gender');
            const tax = await getters.getValor('tax');
            const country = await getters.getValor('country');
            const customerId = user.customer.customerId;

         /*   // Validaciones de seguridad para evitar datos corruptos
            if (!contactName || !contact_last_name || !msisdn) {
              console.warn('Datos incompletos en la fila:', filaObjeto);
              continue; // Saltar esta fila si faltan datos esenciales
            }*/

            // Asignar valores a aux directamente desde filaObjeto
            aux.firstName = filaObjeto[contactName] || '';
            aux.lastName = filaObjeto[contact_last_name] || '';
            aux.address = filaObjeto[address] || '';
            aux.msisdn = filaObjeto[msisdn] || '';
            aux.email = filaObjeto[email] || '';
            aux.birthDate = filaObjeto[birthDate] || '';
            aux.company = filaObjeto[company] || '';
            aux.gender = filaObjeto[gender] || '';
            aux.tax = filaObjeto[tax] || '';
            aux.tags = null;
            aux.segmentId = null;
            aux.active = true;
            aux.createdAt = tiempoActual;
            aux.updatedAt = null;
            aux.country = filaObjeto[country] || '';
            aux.customerId = customerId;
            // Procesar la lista personalizada de contactos coincidentes
            const customContactPlannerList = [];
            for (const elemento of elementosCoincidentes) {
              const aux2 = { ...state.customContactPlanner };
              aux2.contactHeader = filaObjeto[elemento.elementoPrimero.primeraColumna] || '';
              aux2.customerFieldId = elemento.elementoSegundo;

              // Verificación para asegurar que los datos no sean demasiado grandes o corruptos
              if (aux2.contactHeader && customContactPlannerList.length < tamanoLote) {
                customContactPlannerList.push(aux2);
              } else {
                console.warn('Datos inválidos o tamaño excedido en customContactPlannerList.');
                break; // Romper el bucle si los datos no son válidos
              }
            }
            aux.customContactPlannerList = customContactPlannerList;

            // Verificar si el array contactPlannerList existe
            if (!Array.isArray(state.contactPlannerList)) {
              state.contactPlannerList = [];
            }

            // Verificar tamaño antes de hacer push para evitar el error de rango
            if (state.contactPlannerList.length < Number.MAX_SAFE_INTEGER) {
              state.contactPlannerList.push(aux);
            } else {
              console.error('Tamaño máximo de la lista contactPlannerList excedido.');
              throw new RangeError('El tamaño del array contactPlannerList ha excedido el límite permitido.');
            }
          }

          filaInicio += tamanoLote;

          // Si aún quedan filas por procesar, continuar con el siguiente lote
          if (filaInicio < totalFilas) {
            console.log('Procesando lote:', filaInicio, 'de', totalFilas);
            await procesarLote(); // Procesar el siguiente lote
          } else {
            console.log('Procesamiento completado.');
          }
        };

        // Iniciar el procesamiento del primer lote
        await procesarLote();
      } catch (error) {
        console.error('Error en el procesamiento de contactPlanner:', error.message);
      }
    },


    contactPlannerIndividual: (state, getters) => async (user) => {
      const tiempoActual = new Date().getTime();
        const aux = { ...state.contactPlanner };
        const elementosCoincidentes = await getters.customContactPlannerInd();
        const contactName = await getters.getValorInd('first_name');
        const contact_last_name = await getters.getValorInd('last_name');
        const address = await getters.getValorInd('address');
        const msisdn = state.individualNumero;
        const email = await getters.getValorInd('email');
        const birthDate = await getters.getValorInd('birthDate');
        const company = await getters.getValorInd('company');
        const contactGroupId = state.contactGroupId;
        const gender = await getters.getValorInd('gender');
        const tax = await getters.getValorInd('tax');
        const country = await getters.getValorInd('country');
        const customerId = user.customer.customerId;

        if (state.individualNumero) {
          aux.firstName = contactName;
          aux.lastName = contact_last_name;
          aux.address = address;
          aux.msisdn = msisdn
          aux.email = email;
          aux.birthDate = birthDate;
          aux.company = company;
          aux.contactGroupId = contactGroupId;
          aux.gender = gender;
          aux.tax = tax;
          aux.tags = null;
          aux.segmentId = null;
          aux.active = true;
          aux.createdAt = tiempoActual;
          aux.updatedAt = null;
          aux.country = country;
          aux.customerId = customerId;

          const customContactPlannerList = [];
          for (const elemento of elementosCoincidentes) {
            const aux2 = { ...state.customContactPlanner }; // Crear una copia superficial
            aux2.contactHeader = elemento.elementoPrimero.valor;
            aux2.customerFieldId = elemento.elementoSegundo;
            customContactPlannerList.push(aux2);
          }
          aux.customContactPlannerList = customContactPlannerList;
          state.contactPlannerList.push(aux);
        }
    },
    contactPlannerEditar: (state, getters) => async (user) => {
      const tiempoActual = new Date().getTime();
      const tiempoCreate = state.dataEditarContacto.createdAt;
      const aux = { ...state.dataContactPlannerEditar };
      const elementosCoincidentes = await getters.customContactPlannerInd();

      const address = await getters.getValorInd('address');
      const email = await getters.getValorInd('email');
      const birthDate = await getters.getValorInd('birthDate');
      const company = await getters.getValorInd('company');
      const gender = await getters.getValorInd('gender');
      const tax = await getters.getValorInd('tax');
      const country = await getters.getValorInd('country');

      if (state.editarContacto) {
        aux.contactPlannerId = state.dataEditarContacto.contactPlannerId;
        aux.contactName = state.dataEditarContacto.contactName;
        aux.contact_last_name = state.dataEditarContacto.contact_last_name;
        aux.address = address;
        aux.msisdn = state.individualNumero;
        aux.email = email;
        aux.birthDate = birthDate;
        aux.company = company;
        aux.gender = gender;
        aux.tax = tax;
        aux.tags = null;
        aux.segmentId = state.dataEditarContacto.segmentId;
        aux.contactGroupId = state.dataEditarContacto.contactGroupId;
        aux.active = true;
        aux.createdAt = tiempoCreate;
        aux.updatedAt = tiempoActual;
        aux.country = country;
        aux.customerId = user.customer.customerId;

        const customContactPlannerList = [];
        for (const elemento of elementosCoincidentes) {
          const aux2 = { ...state.customContactPlanner }; // Crear una copia superficial
          aux2.contactHeader = elemento.elementoPrimero.valor;
          aux2.customerFieldId = elemento.elementoSegundo;
          customContactPlannerList.push(aux2);
        }
        aux.customContactPlannerList = customContactPlannerList;
      }
      state.dataContactPlannerEditar = aux;
      state.get_filtros = [];
      state.vista2 = false;
      state.responseTags = [];
      state.botonesCab = [];
      state.addCab = [];
      state.datoTag = [];
      state.individual = false;
    },
    customContactPlanner: state => async () => {
      // Crear un arreglo para almacenar los elementos coincidentes
      const elementosCoincidentes = [];
      // Recorrer ambos arreglos
      state.datosPorFilas.forEach(elementoPrimero => {
        // Verificar si ya se encontró una coincidencia para el elementoPrimero
        let encontrado = false;
        state.responseCustomer_field_available.forEach(elementoSegundo => {
          if (elementoPrimero.segundaColumna === "agregar"){
            if (
                !encontrado &&
                (
                    elementoPrimero.primeraColumna.localeCompare(elementoSegundo.customerFieldName, undefined, { sensitivity: 'case' }) === 0
                )
            ) {
              // Agregar el primer elemento coincidente y marcar como encontrado
              elementosCoincidentes.push({
                elementoPrimero: elementoPrimero,
                elementoSegundo: elementoSegundo,
              });
              encontrado = true; // Salir del bucle interno al encontrar el primer match
            }
          }else {
            if (
                !encontrado &&
                (
                    elementoPrimero.segundaColumna.localeCompare(elementoSegundo.customerFieldName, undefined, {sensitivity: 'case'}) === 0
                )
            ) {
              // Agregar el primer elemento coincidente y marcar como encontrado
              elementosCoincidentes.push({
                elementoPrimero: elementoPrimero,
                elementoSegundo: elementoSegundo,
              });
              encontrado = true; // Salir del bucle interno al encontrar el primer match
            }
          }
        });
      });

      return elementosCoincidentes;
    },
    customContactPlannerInd: state =>  async () => {
      // Crear un arreglo para almacenar los elementos coincidentes
      const elementosCoincidentes = [];
      // Recorrer ambos arreglos
      state.botonesCab.forEach(cabecera => {
        state.responseCustomer_field_available.forEach(elementoSegundo => {
          // Verificar si alguna de las columnas coincide con customerFieldName
          if (cabecera.cabecera === elementoSegundo.customerFieldName) {
            // Agregar el elemento del primer arreglo a la lista de coincidencias
            elementosCoincidentes.push({
              elementoPrimero: cabecera,
              elementoSegundo: elementoSegundo,
            });
          }
        });
      });
      state.addCab.forEach(cabecera => {
        state.responseCustomer_field_available.forEach(elementoSegundo => {
          // Verificar si alguna de las columnas coincide con customerFieldName
          if (cabecera.cabecera === elementoSegundo.customerFieldName) {
            // Agregar el elemento del primer arreglo a la lista de coincidencias
            elementosCoincidentes.push({
              elementoPrimero: cabecera,
              elementoSegundo: elementoSegundo,
            });
          }
        });
      });
      return elementosCoincidentes;
    },

    getFiltro: state => async (data) => {
      try {
        let user = (getUserSub()!=null) ? getUserSub() :getUser();

        let js = {
          "time_expired":horaAhora(),
          "customer":encodeURIComponent(JSON.stringify(user.customer)),
          "filter":encodeURIComponent(JSON.stringify(data)),
        }
        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await get_filtros(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {
          state.get_filtros = response.data.data;
        } else {
          console.log("No se encontró lista");
          return null;
        }
      } catch (error) {
        console.error("Error al obtener datos", error);
        return null;
      }
    },
    getTipo: state => (nuevoValor) => {
      const foundFila = state.responseGeneric_field.find(fila => fila.headerName === nuevoValor);
      return foundFila ? "basic" : "custom";
    },

    getName: state => (nuevoValor) => {
      const basicField = state.responseGeneric_field.find(fila => fila.basicHeaderFieldId === nuevoValor);

      if (basicField) {
        return basicField.headerName;
      }

      const customerField = state.responseCustomer_field_available.find(fila => fila.basicHeaderFieldId === nuevoValor);

      if (customerField) {
        return customerField.customerFieldName;
      }

      // Si no se encuentra ningún nombre, puedes devolver un valor predeterminado o null
      return null;
    },

    //Dado un id de Segmento devuelve el segmento
    getSegmento: state => (nuevoValor) => {
      let segment = "";
      state.responseSegmentos.forEach((fila) => {
        if (fila.segmentTagId === nuevoValor) {
          segment = fila;
        }
      });
      return segment;
    },
    //Dado un id de Tag devuelve el Tag
    getTag: state => (nuevoValor) => {
      let tag = "";
      state.responseTags.forEach((fila) => {
        if (fila.segmentTagId === nuevoValor) {
          tag = fila;
        }
      });
      return tag;
    },
    limpiarVariables: state => () => {
          state.responseCustomer = [];
          state.cabeceras = [];
          state.responseTags = [];
          state.responseSegmentos = [];
          state.responsePlantillasSms = [];
          state.datoSegmento = "";
          state.datoTag = [];
          state.datosPorFilas = [];
          state.valorMsisdn = "";
          state.contactPlannerList = [];
          state.botonesCab = [];
          state.addCab = [];
          state.cabecerasIndGenericas = [];
          state.cabecerasIndNoGen = [];
          state.cabecerasIndAgregar = [];
          state.get_filtros = [];
          state.vista2 = false;
    },
    //Listar plantillas de whatsapp
    list_template_whatsapp: state =>  async () => {
      try {
        let user = (getUserSub()!=null) ? getUserSub() :getUser();

        let js = {
          time_expired: horaAhora(),
          "customer":encodeURIComponent(JSON.stringify(user.customer)),
        };

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await LIST_TEMPLATE_WHATSPP_BY_CUSTOMER(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {
          state.LIST_TEMPLATE_WHATSPP_BY_CUSTOMER = response.data.data;
        } else {
          console.log("No se encontró lista");

        }
      } catch (error) {
        console.error("Error al obtener datos", error);
      }
    },
    //Acortar Url agregada en SMS
    add_short_urls: () => async (nuevoValor) => {
      try {
        let user = (getUserSub()!=null) ? getUserSub() :getUser();

        let short_url = {
          "flexUrlId": null,
          "shortUrl": null,
          "longUrl": nuevoValor,
          "customerId": user.customer.customerId,
          "createdAt": null,
          "read": 0,
          "type": 1, //1 url general y 2 url dinamica
          "contactField": '{}'
        }

        let js = {
          time_expired: horaAhora(),
          "customer":encodeURIComponent(JSON.stringify(user.customer)),
          "short_url":encodeURIComponent(JSON.stringify(short_url)),
        };

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await add_short_url(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {
          return response.data.data;
        } else {
          console.log("No se pudo acortar URl");

        }
      } catch (error) {
        console.error("No se pudo acortar URl", error);
      }
    },
    //Listar URls acortadas
    available_short_urls: state =>  async () => {
      try {
        let user = (getUserSub()!=null) ? getUserSub() :getUser();

        let js = {
          time_expired: horaAhora(),
          "customer":encodeURIComponent(JSON.stringify(user.customer)),
        };

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await available_short_url(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {
          state.list_available_short_url = response.data.data;
        } else {
          console.log("No se encontró lista");

        }
      } catch (error) {
        console.error("Error al obtener datos", error);
      }
    },

    //sender email
    obtenerSenderEmail: () => async () => {
      try {
        let user = (getUserSub()!=null) ? getUserSub() :getUser();

        let js = {
          "time_expired":horaAhora(),
          "customer":encodeURIComponent(JSON.stringify(user.customer))
        }

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await obtenerSenderEmail(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {

         return response.data.data;
        } else {
          console.log("No se encontró lista");

        }
      } catch (error) {
        console.error("Error al obtener datos", error);
      }
    },

    //Domain email
    obtenerDomainEmail: () => async () => {
      try {
        let user = (getUserSub()!=null) ? getUserSub() :getUser();

        let js = {
          "time_expired":horaAhora(),
          "customer":encodeURIComponent(JSON.stringify(user.customer))
        }

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await obtenerDomainEmail(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {

          return response.data.data;
        } else {
          console.log("No se encontró lista");

        }
      } catch (error) {
        console.error("Error al obtener datos", error);
      }
    },
    obtenerSenderSms: (state) => async () => {
      try {
        let user = (getUserSub()!=null) ? getUserSub() :getUser();

        let js = {
          "time_expired":horaAhora(),
          "customer":encodeURIComponent(JSON.stringify(user.customer))
        }

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await obtenerSender(body);
        const responseCustomer = await obtenerSenderCustomer(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200" && responseCustomer.data.response === "200") {

          state.responseSenderSms = response.data.data;
          state.responseSenderSmsCustomer = responseCustomer.data.data;
        } else {
          console.log("No se encontró lista");

        }
      } catch (error) {
        console.error("Error al obtener datos", error);
      }
    },
    obtenerContactGroups: (state) => async () => {
      try {
        let user = (getUserSub()!=null) ? getUserSub() :getUser();

        let js = {
          "time_expired":horaAhora(),
          "customer":encodeURIComponent(JSON.stringify(user.customer))
        }

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));


        const response = await contact_groups(body);
        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {
          state.responserContactGroups = response.data.data;
        } else {
          console.log("No se encontró lista");

        }
      } catch (error) {
        console.error("Error al obtener datos", error);
      }
    },

    obtenerEstadoCanales: state => async () => {
      try {
        let user = (getUserSub() != null) ? getUserSub() : getUser();

        let js = {
          "time_expired": horaAhora(),
          "customer": encodeURIComponent(JSON.stringify(user.customer)),
        }

        var body = new URLSearchParams();
        body.append("TOKEN", encryptAES(js));

        const response = await status_channel(body);

        // Almacena la respuesta en la variable de datos
        if (response.data.response === "200") {
          state.estado_canales = response.data.data;
        } else {
          console.log("No se encontró lista");
        }

        // Devuelve la respuesta
        return response;
      } catch (error) {
        console.error("Error al obtener datos", error);
        throw error; // Lanza el error para que pueda ser manejado externamente si es necesario
      }
    },
  },
});
